import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import ScrollToTop from './components/common/ScrollToTop'
import Spinner from "./components/common/Spinner";
import Case from "./pages/Case";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Suspense, useEffect } from "react";

const Home = React.lazy(() => import('./pages/Home'));
const DialogForm = React.lazy(() => import('./pages/DialogForm'));
const Stroim = React.lazy(() => import('./pages/Stroim'));
const Smm = React.lazy(() => import('./pages/Smm'));
const Logo = React.lazy(() => import('./pages/Logo'));
const Brand = React.lazy(() => import('./pages/Brand'));
const BrandBook = React.lazy(() => import('./pages/BrandBook'));
const Naming = React.lazy(() => import('./pages/Naming'));
const Website = React.lazy(() => import('./pages/Website'));
const BrandKey = React.lazy(() => import('./pages/BrandKey'));
const Cases = React.lazy(() => import('./pages/Cases'));
const Design = React.lazy(() => import('./pages/Design'));
const Services = React.lazy(() => import('./pages/Services'));
const Consulting = React.lazy(() => import('./pages/Consulting'));
const Test = React.lazy(() => import('./pages/Test'));
const Rebrand = React.lazy(() => import('./pages/Rebrand'));
const Ad = React.lazy(() => import('./pages/Ad'));
const Vacancies = React.lazy(() => import('./pages/Vacancies'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Strategy = React.lazy(() => import('./pages/Strategy'));
const Merch = React.lazy(() => import('./pages/Merch'));


function App() {

	useEffect(() => {
		AOS.init()
	}, [])
	
	return (
		<BrowserRouter>
			<ScrollToTop/>
			<Suspense fallback={
				<div className='flex items-center justify-center h-[100vh]'>
                    <Spinner/>
                </div>
			}>
				<Routes>
					<Route path="/" element={<Layout/>}>
						<Route index element={<Home/>} />
						<Route path="dialog" element={<div className="container mx-auto"><DialogForm/></div>} />
						<Route path="stroim" element={<Stroim/>} />
						<Route path="smm" element={<Smm/>} />
						<Route path="logo" element={<Logo/>} />
						<Route path="brand" element={<Brand/>} />
						<Route path="brandbook" element={<BrandBook/>} />
						<Route path="naming" element={<Naming/>} />
						<Route path="website" element={<Website/>} />
						<Route path="brand-key" element={<BrandKey/>} />
						<Route path="cases" element={<Cases/>} />
						<Route path="cases/:id" element={<Case/>} />
						<Route path="design" element={<Design/>} />
						<Route path="services" element={<Services/>} />
						<Route path="consulting" element={<Consulting/>} />
						<Route path="test" element={<Test/>} />
						<Route path="rebrand" element={<Rebrand/>} />
						<Route path="ad" element={<Ad/>} />
						<Route path="vacancies" element={<Vacancies/>} />
						<Route path="contact" element={<Contact/>} />
						<Route path="strategy" element={<Strategy/>} />
						<Route path="merch" element={<Merch/>} />
					</Route>
					{/* <Route path="*" element={<Navigate to={"/"} replace />} /> */}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
